<template>
    <div v-if="paymentDetail !== null" class="mt-4">
      <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl relative w-full">
        <div class=" text-text2 heading-4 absolute left-3 mr-1 top-2 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="$router.go(-1)">
            <i class="fa-solid fa-arrow-left"></i>
          </div>
        <div class="flex justify-center items-center ">
          <h1 class="heading-2 text-center">Payment Detail: #{{paymentDetail.paymentNo}}</h1>
        </div>
      </div>
       <div class="py-2">
        <div class="card bg-white rounded-xl p-4 my-2" v-if="paymentDetail.paymentNotes !== ''">
          <div class="text-gray3 font-bold">Payment Notes </div>
          <div class="text-text1 heading-5">{{paymentDetail.paymentNotes}}</div>
        </div>
      </div>
      <div>
           <div class="lg:grid grid-cols-6 gap-4">
            <div class="col-span-3 xl:col-span-2 2xl:col-span-2 mb-4">
                <div class="card bg-white p-4 rounded-xl">
                  <div class=" text-white text-center bg-success mb-4 p-4 rounded-xl">
                    <p class="">Amount Received</p>
                    <p class="heading-2 font-bold">{{paymentDetail.paymentAmount.toFixed(2) | amountOnlyNumber}}</p>
  
                  </div>
                  <div class="mb-4">
                    <p class="heading-6 text-gray4">Customer</p>
                    <span class="cursor-pointer text-primary heading-4" @click.stop="customerDetail()">{{paymentDetail.companyName}}</span>
                  </div>
                  <div class="mb-4 flex justify-between">
                    <div>
                      <p class="heading-6 text-gray4">Payment Method</p>
                      <p class="text-text2 heading-4">{{paymentDetail.paymentMethodName}}</p>
                    </div>
                    <div>
                      <p class="heading-6 text-gray4 text-right">Payment Date</p>
                      <p class="text-text2 heading-4 text-right">{{paymentDetail.addedDate | dateFilters}}</p>
                    </div>
                  </div>
                  <div class=" flex justify-between">
                    <div>
                      <p class="heading-6 text-gray4">Transaction Id</p>
                      <p class="text-text2 heading-4" v-if="paymentDetail.transactionId !== ''">{{paymentDetail.transactionId}}</p>
                      <p class="text-text2 heading-4" v-else>-</p>
                    </div>
                    <div>
                      <p class="heading-6 text-gray4 text-right">Recorded By</p>
                      <p class="text-text2 heading-4 text-right">{{paymentDetail.createdByUserFirstName}} {{paymentDetail.createdByUserLastName}}</p>
                    </div>
                  </div>
                </div>
            </div>
            <div class=" col-span-3 xl:col-span-4 2xl:col-span-4 mb-10">
               <div class="card bg-white rounded-xl p-4 mb-4">
                    <p class=" text-gray4 heading-4 font-bold mb-4">Invoices Linked:</p>
                    <div style="overflow-y: auto; white-space: nowrap;" class="">
                      <div v-if="paymentDetail.linkedInvoiceList.length > 0">
                          <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
                              <div class="table_col w-32  flex items-center">
                              <span class="">{{titleObj[0].title}}</span>
                              </div>
                              <div class="table_col w-44  flex items-center">
                              <span class="">{{titleObj[1].title}}</span>
                              </div>
                              <div class="table_col w-44  flex items-center">
                              <span class="">{{titleObj[2].title}}</span>
                              </div>
                              <div class="table_col w-32  flex items-center">
                              <span class="">{{titleObj[3].title}}</span>
                              </div>
                              <div class="table_col w-32  flex items-center">
                              <span class="">{{titleObj[4].title}}</span>
                              </div>
                              <div class="table_col w-32  flex items-center">
                              <span class="">{{titleObj[5].title}}</span>
                              </div>
                          </div>
                              
                              <div v-for="(data, index) in paymentDetail.linkedInvoiceList" :key="index" @click="openInvoiceDetails(data.invoiceId)" class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer">
                              <div class="table_col w-32 ">
                                  <div class="">
                                  <p class="  text-text2">#{{data.invoiceNumber}}</p>
                                  </div>
                              </div>
                              <div class="table_col w-44 ">
                                  <div class="flex items-center">
                                  <span class="  text-gray4 heading-6">{{data.grandTotal | amountOnlyNumber}}</span>
                                  </div>
                              </div>
                              <div class="table_col w-44 ">
                                  <div class="flex items-center">
                                  <span class="  text-text2 heading-6">{{data.recordedAmount | amountOnlyNumber}}</span>
                                  </div>
                              </div>
                              <div class="table_col w-32">
                                  <div class="flex items-center">
                                  <span class="  text-gray4 heading-6">{{data.invoiceDate | formatForDatePicker}}</span>
                                  </div>
                              </div>
                              <div class="table_col w-32 ">
                                  <div class="flex items-center">
                                  <span class="  text-gray4 heading-6">{{data.dueDate | formatForDatePicker}}</span>
                                  </div>
                              </div>
                              <div class="table_col w-32 ">
                                  <div class="flex items-center">
                                  <span :style="`color: ${data.invoiceStatusColor};`" class=" text-gray4 heading-6">{{data.invoiceStatusName}}</span>
                                  </div>
                              </div>
                              </div>
                      </div>
                      <div v-else>
                        <NoRecords :alertMessage="` No records found.`" />
                      </div>
                    </div>
                  </div>
            </div>
           </div>
      </div>
    </div>
  </template>
  <script>
  import ADMINAPI from '@/View/Admin/api/Admin.js'
  import NoRecords from '@/View/components/noRecords.vue'
  export default {
    name: "customer",
    components: {
      NoRecords,
    },
    data() {
      return {
        removeConfirm: false,
          paymentDetail: null,
          titleObj: [
            {title: 'Invoice Number', icon: '', sortName: ''},
            {title: 'Invoice Total', icon: '', sortName: ''},
            {title: 'Amount', icon: '', sortName: ''},
            {title: 'Date', icon: '', sortName: ''},
            {title: 'Due Date', icon: '', sortName: ''},
            {title: 'Status', icon: '', sortName: ''},
          ],
      };
    },
    created() {},
    mounted() {
      this.getPaymentDetails()
    },
    methods: {
      customerDetail () {
        this.$router.push({name: 'CustomersDetail', params: {customerId: this.paymentDetail.customerId}})
      },
      editPayment () {
        this.$router.push({name: 'recordPayment', query:{customerId: this.paymentDetail.customerId, paymentId: this.paymentDetail.paymentId}})
      },
       openInvoiceDetails (invoiceId) {
        this.$router.push({name: 'InvoiceDetail', params: {invoiceId: invoiceId}})
      },
      getPaymentDetails() {
        this.$store.dispatch("SetLoader", {
          status: true,
          message: "Loading...",
        });
        ADMINAPI.GetPaymentDetail(
          parseInt(this.$route.params.paymentId),
          (response) => {
            this.paymentDetail = response.Data
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (err) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
      },
    },
    beforeDestroy() {
      this.$root.$off("confirmBoxHandler");
    },
  };
  </script>
  <style scoped>
  .table_col {
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    padding-right: 8px;
  }
  .name_col {
      min-width: 200px;
      max-block-size: 200px;
  }
  .des_col {
      min-width: 250px;
      max-block-size: 250px;
  }
  .pric_col {
      min-width: 100px;
      max-block-size: 100px;
  }
  .qnty_col {
      min-width: 100px;
      max-block-size: 100px;
  }
  .amt_col {
      min-width: 100px;
      max-block-size: 100px;
  }
  </style>